.catalogTabs div {
    padding: 15px 5px;
}

.productCard {
    box-shadow: 0px 0px 15px rgb(202, 202, 202);
    transition: all ease-in-out 0.2s;
}
.productCard:hover {
    transform: scale(0.98);
}

.ant-card .ant-card-meta-title {
    font-size: 20px !important;
}