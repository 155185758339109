.main{
  padding-top: 100px;
}
.floatBtn {
  background: repeating-linear-gradient(45deg, rgb(23, 91, 131), transparent 100px) !important;
  color: white !important;
}
.floatBtn:focus {
  outline: none;
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.adminlogo{
  float: left;
  width: 120px;
  height: 50px;
  margin: 16px 24px 16px 0;
  background: url("../../../public/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.siteMenu li{
  padding: 0 !important;
}
.siteMenu > li > span span{
  display: block;
  padding: 0px 25px !important;
}
.siteMenu ul > li > span span{
  display: block;
  padding: 0px 35px !important;
}

.catalogFooter input:focus, .catalogFooter textarea:focus{
  background: white;
}

.lazyLoad{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media only screen and (max-width: 992px) {
  .main {
    padding-top: 0px;
  }
}