.premiumItem {
    transition: all ease-in-out 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1px;
      height: 150px;
      padding: 5px 0px !important;
  }
  
  .premiumItem img {
    max-width: 100%;
    max-height: 70%;
    height: 100%;
  }
  .premiumItem:hover {
    transform: scale(1.1);
    cursor: pointer;
  }