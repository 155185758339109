.testimonialItem {
    padding: 20px;
    background: white;
    border-radius: 15px;
    text-align: center;
    height: 350px;
    margin-right: 10px;
}

.testimonialItem h5 {
    margin: 15px;
}

.testimonialItem h6 {
    margin-top: 15px;
    margin-bottom: 5px;
}

.testimonialItem p {
    padding: 15px 25px;
}

.countryItem div {
    background: white;
    height: 120px;
    width: 120px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.countryItem div a {
    padding: 0px 20px;
    max-height: 75px;
}