body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-main-primary{
  background-color: #E97D1F !important;
}
.text-main-primary{
  color: #E97D1F !important;
}

.bg-main-secondary{
  background-color: #169ECC !important;
}
.text-main-secondary{
  color: #169ECC !important;
}