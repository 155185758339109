.formInput {
        width: 100%;
}

.formInput input:focus,
.formInput textarea:focus {
        border: none;
}

.formInput label {
        z-index: 0 !important;
}

.formInput textarea {
        max-height: 100px;
}

.bannerItem,
.galleryItem {
        position: relative;
}

.bannerDelete,
.galleryDelete {
        position: absolute;
        top: 5px;
        right: 5px;
        opacity: 0;
        transition: all ease-in-out 0.3s;
}

.galleryDelete button,
.bannerDelete button {
        border-radius: 50%;
        padding: 5px 10px;
}

.bannerItem:hover div,
.galleryItem:hover div {
        opacity: 1;
}

.rowItems td h4 {
        background: #169ECC;
        border-radius: 20px;
        padding: 5px 10px;
        color: white;
}

.planContainer {
        overflow: hidden;
        margin-bottom: 20px;
}

.planContainer th,
.planContainer td {
        border: solid 1px rgb(73, 73, 73);
}